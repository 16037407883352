<template>
    <div>
        <vxe-table
            show-overflow
            :show-header="false"
            :data="reports_menu"
            border="none"
            :tree-config="{children: 'children', line: false}"
            size="mini"
            highlight-hover-row
            highlight-current-row
            class="page-reporting-sidebar-table"
            ref="ReportsListTree"
            v-loading="user_reports_loading"
        >
            <vxe-table-column>
                <template #default="{ row }">
                    <div class="prs-table-row" @click="set_selected_report(row)">
                        <div class="prs-table-row-sign">
                            <template v-if="row.children && $refs.ReportsListTree.isTreeExpandByRow(row) && row.id === 'favs'">
                                <svg-icon icon-class="star_fill" />
                            </template>
                            <template v-else-if="!row.parent_id && row.id === 'favs'">
                                <svg-icon icon-class="star" />
                            </template>
                            <template v-else-if="row.children && $refs.ReportsListTree.isTreeExpandByRow(row)">
                                <i class="vxe-icon--minus"></i>
                            </template>
                            <template v-else-if="row.children && row.children.length && !row.parent_id">
                                <i class="vxe-icon--plus"></i>
                            </template>
                        </div>
                        <div class="prs-table-row-name" v-bind:class="define_report_class(row)">
                            {{ row.title }}
                        </div>
                    </div>
                </template>
            </vxe-table-column>
        </vxe-table>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import XEUtils from 'xe-utils'
import pl_sys_tpls from './Reports/PlReport/SystemTemplates.json'

export default {
    data(){
        return {
            reports: [],
            reports_categories: [
                { id: 'favs',        title: 'Favorites' },
                { id: 'pnl',         title: 'P&L reports' },
                { id: 'time_series', title: 'Time Series reports' },
                { id: 'transactions',title: 'Transactions reports' },
                { id: 'positions',   title: 'Positions reports' },
                { id: 'nav',         title: 'NAV reports' },
                { id: 'taxlots',     title: 'Tax lots reports' },
                { id: 'exposure',    title: 'Exposure reports' },
            ],

            reports_menu : [],
            expanded_rows_ids: []
        }
    },

    computed: {
        ...mapState({
            user                 : state => state.app.user,
            user_reports_list    : state => state.userReports.list,
            user_reports_loading : state => state.userReports.dataLoading,
            user_reports_favorites : state => state.userReportsFavorites.list
        }),
    },

    methods: {
        set_selected_report(row){
            if (!row.parent_id) {
                this.$refs.ReportsListTree.toggleTreeExpand(row)
            }
            else {
                this.$emit('selected-report', row)
            }
        },
        open_report(selected_report){
            let table_data = this.$refs.ReportsListTree.getTableData()
            let row = XEUtils.findTree(table_data.visibleData, item => item.id === selected_report.id)
            if (row) {
                this.$refs.ReportsListTree.setTreeExpand([row.parent], true)
                this.$refs.ReportsListTree.setCurrentRow(row.item)
                //todo: change behavior
                if (selected_report.new)
                    this.$emit('selected-report', row.item);
                else
                    this.$emit('set-report-etag', row.item._etag)
            }
        },
        refresh(){
            this.$refs.ReportsListTree.refreshColumn()
        },
        reload_favorites_reports(){
            this.reports = this.reports.filter((report) => report.parent_id !== 'favs')
            this.user_reports_favorites.forEach(favorite_report => {
                if(favorite_report.system){
                    pl_sys_tpls.forEach(tpl => {
                        if(
                            favorite_report._id === tpl.id
                            && !this.reports.some(report => report.parent_id === 'favs' && report.id === favorite_report._id)
                        ){
                            let new_menu_item = JSON.parse(JSON.stringify(tpl))
                            new_menu_item.category = "pnl"
                            new_menu_item.parent_id = 'favs'
                            new_menu_item.system = true
                            this.reports.push(new_menu_item)
                        }
                    })
                } else {
                    let tpl = {
                        id: favorite_report._id,
                        _etag: favorite_report._etag,
                        title: favorite_report.title,
                        description: favorite_report.description,
                        category: favorite_report.category,
                        parent_id: 'favs',
                        system: false,
                        filters: favorite_report.content.filters || [],
                        columns: favorite_report.content.columns || [],
                        groupby: favorite_report.content.groupby || [],
                        sortby: favorite_report.content.sortby || [],
                    }

                    this.reports.push(tpl)
                }
            })
        },
        save_expanded_rows_ids(){
            let rows = this.$refs.ReportsListTree.getTreeExpandRecords()
            rows.forEach((row) => {
                this.expanded_rows_ids.push(row.id)
            })
        },
        reopen_expanded_rows(){
            let rows = this.reports_menu.filter(menu_item =>
                this.expanded_rows_ids.some((expanded_row_id) => menu_item.id === expanded_row_id)
            )

            this.$refs.ReportsListTree.setTreeExpand(rows, true)
        },
        reload_reports_menu(){
            let tmp = JSON.parse(JSON.stringify(this.reports));
            this.reports_menu = XEUtils.toArrayTree(JSON.parse(JSON.stringify(this.reports)), {key: "id", parentKey: "parent_id"});
            this.reports = tmp
        },
        load_list(selected_report) {
            return Promise.all([
                this.$store.dispatch('userReports/getList', {user_id: this.user.id}),
                this.$store.dispatch('userReportsFavorites/getFavorites')
            ]).then(() => {
                this.reports = JSON.parse(JSON.stringify(this.reports_categories))

                pl_sys_tpls.forEach(tpl => {
                    tpl.category = "pnl"
                    tpl.parent_id = "pnl"
                    tpl.system = true
                    this.reports.push(tpl)
                })

                this.reports.forEach(report => {
                    if (!report.columns) report.columns = [];
                    if (!report.groupby) report.groupby = [];
                    if (!report.sortby) report.sortby = [];
                })


                this.user_reports_list.forEach(record => {
                    let tpl = {
                        id: record._id,
                        _etag: record._etag,
                        title: record.title,
                        description: record.description,
                        parent_id: record.category,
                        category: record.category,
                        system: false,
                        filters: record.content.filters || [],
                        columns: record.content.columns || [],
                        groupby: record.content.groupby || [],
                        sortby: record.content.sortby || [],
                    }
                    this.reports.push(tpl)
                })
                this.reload_favorites_reports()

                this.reload_reports_menu()

                if (selected_report) {
                    let new_selected_report = this.reports.find((report) => selected_report.id === report.id)
                    this.$nextTick(() => {
                        this.open_report(new_selected_report)
                    })
                }
            })
        },
        define_report_class(row) {
            if (row.parent_id && row.system) 
                return 'prs-table-row-name-l2';
            else if (row.parent_id )
                return 'prs-table-row-name-personal';
            else
                return 'prs-table-row-name-l1';
        }
    },

    watch: {
        user_reports_favorites(){
            this.save_expanded_rows_ids()

            this.reload_favorites_reports()

            this.reload_reports_menu()

            this.reopen_expanded_rows()

        },
    }
}
</script>
